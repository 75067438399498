<template>
    <div class="wrapper-menu wrapper-menu--contact">
        <div class="container-menu">
            <div :class="`container-menu__logo ${$i18n.locale}`">
               <div class="nav-logo"> 
                    <a :href="getHRef()">
                        <img class="menu-logo" alt="Logo" :src="logo()">
                    </a>
                </div>
            </div>
            <nav class="container-menu__nav">
                <ul class="menu-contact">
                    <li><router-link :to="{ name: 'map'}"><i><font-awesome-icon icon="store-alt" class="mr-2"/></i>{{ $t('headerComponent.stores_map') }}</router-link></li>
                    <li>
                        <a target="_blank" :href="clientArea">
                            <i><font-awesome-icon icon="user" class="mr-2"/></i>{{ $t('headerComponent.client_area') }}
                        </a>
                    </li>
                    <li v-if="contactInfo.whatsappIsEnabled && !isHoliday()">
                        <a class="whatsapp" tabindex="0" title="Whatsapp" :href="`https://api.whatsapp.com/send?phone=${contactInfo.whatsapp}`">
                            <i><font-awesome-icon :icon="['fab', 'whatsapp']" class="mr-2" /></i><span data-nosnippet="">WhatsApp</span>
                        </a>
                    </li>
                    <li>
                        <a :href="`tel:${contactInfo.telephone} `">
                            <i><font-awesome-icon icon="phone-alt" class="mr-2"/></i><span>{{ contactInfo.telephone }} </span>
                        </a>
                    </li>
                </ul>
                <ul class="menu-contact-mobile-version">
                    <li v-if="contactInfo.whatsappIsEnabled && !isHoliday()">
                        <a class="whatsapp" tabindex="0" title="Whatsapp" :href="`https://api.whatsapp.com/send?phone=${contactInfo.whatsapp}`">
                        <i><font-awesome-icon :icon="['fab', 'whatsapp']" class="mr-2" /></i><span data-nosnippet="">WhatsApp</span></a>
                    </li>
                    <li>
                        <a :href="`tel:${contactInfo.telephone}`">
                        <i><font-awesome-icon icon="phone-alt" class="mr-2"/></i><span>{{ contactInfo.telephone }}</span></a>
                    </li>

                </ul>
                <button type="button" class="btn-orange" @click="showCallRequestModal">{{ $t('headerComponent.contact_us') }}</button>
                <button id="ham" class="ham" @click="toggleHam">
                    <span>Open / Close menu</span>
                </button>
                <CallRequestModal 
                    ref="callRequestRef" 
                    :phoneContact= "contactInfo.telephone"
                    showQueryBox= true
                    showHelpBox= true
                    @show-report-sinister="showReportSinisterModal"
                    @show-thanks="showThanksModal" />
                <ReportSinisterModal 
                    ref="reportSinisterRef"
                    :currentLanding='currentLanding' />
                <ThanksModal 
                    ref="thanksRef" />
            </nav>
        </div>
    </div>
</template>

<script>
import CallRequestModal from '../../components/modals/CallRequestModal.vue'
import ReportSinisterModal from '../../components/modals/ReportSinisterModal.vue'
import ThanksModal from '../../components/modals/ThanksModal.vue'
import navLink from '@/config/constants/navLink.js'
import landing from '@/config/constants/landing.js'
import contactInfo from '@/config/constants/contactInfo.js'

export default {
    name: 'HeaderOptions',
    props: ['currentLanding'],
    components: { 
        CallRequestModal,
        ReportSinisterModal,
        ThanksModal
    },
    data(){
        return{
            navLink,
            landing,
            contactInfo
        }
    },
    methods: {
        showCallRequestModal(){
            this.$refs.callRequestRef.showModal()
        },
        showReportSinisterModal(){
            this.$refs.reportSinisterRef.showModal()
        },
        showThanksModal(){
            this.$refs.thanksRef.showModal()
        },
        toggleHam() {
            document.body.classList.toggle('nav-open')
        },
        logo() {
            try {
                return require(`@/assets/logo/${this.$i18n.locale}_${this.currentLanding}.svg`)
            } catch (e) {
                return require(`@/assets/logo/${this.$i18n.locale}_${this.currentLanding}.png`)
            }
        },
        getHRef(){
            switch (this.currentLanding) {
                case landing.seguromovil.name:
                    return navLink.seguromovil
                case landing.seguropatinete.name:
                    return navLink.seguropatinete
                case landing.seguromultidispositivo.name:
                    return navLink.seguromultidispositivo
                case landing.seguroportatil.name:
                    return navLink.seguroportatil
                case landing.extensiondegarantia.name:
                    return navLink.extensiondegarantia
                default:
                    return ""
            }
        }
    },
    computed: {
        clientArea(){
            return process.env.VUE_APP_CLIENT_AREA_URL 
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
