<template>
    <b-modal ref="reportSinisterRef" centered hide-header hide-footer>
        <div class="modal-center">
            <a class="btn-close-modal pr-4 pointer" @click="hideModal" style="font-size:30px;">
                <font-awesome-icon :icon="['fas', 'times']" />
            </a>
            <br><br>
            <div class="text-center">
                <div class="text-center">
                    <div class="modal-logo">
                        <img :src="logo()" class="logo" height="200">
                    </div>
                    <br>
                    {{ $t('reportSinisterModal.help_title') }}
                    <br><br>
                    {{ $t('reportSinisterModal.download_app') }}
                    <br><br>
                    <a :href= contactInfo.android target="_blank"><img :src="contactInfo.androidLogo" style="width: 150px;"></a>
                    <br>
                    <a :href= contactInfo.ios target="_blank"><img :src="contactInfo.iosLogo" style="width: 150px;"></a>
                    <br><br>
                    {{ $t('reportSinisterModal.or_access') }}
                    <br><br>
                    <div id="botones">
                        <div class="row justify-content-md-center">
                            <div class="col-12 col-lg-auto">
                                <a class="btn-white-image" target="_blank" :href="clientArea"><img src="../../assets/icons/area-cliente.png" height="25" style="vertical-align:top"> {{ $t('reportSinisterModal.client_area') }} </a>
                            </div>
                        </div>
                    </div>
                    <br><br>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import contactInfo from '@/config/constants/contactInfo.js'

export default { 
    props: ['currentLanding'],
    data: () => ({
        contactInfo
    }),
    methods: {
        showModal() {
            this.$refs.reportSinisterRef.show()
        },
        hideModal() {
            this.pushGTM('funnel', 'cancelar', 'recurrente')
            this.$refs.reportSinisterRef.hide()
        },
        logo() {
            try {
                return require(`@/assets/logo/${this.$i18n.locale}_${this.currentLanding}.svg`)
            } catch (e) {
                return require(`@/assets/logo/${this.$i18n.locale}_${this.currentLanding}.png`)
            }
        },
        androidAppLogo() {

        }
    },
    computed: {
        clientArea(){
            return process.env.VUE_APP_CLIENT_AREA_URL 
        }
    }
}
</script>

<style lang="scss" scoped>
.modal-logo{
    width: 150px;
    height: auto;
    margin: 0 auto;
}
.modal-logo img{
    width: 100%;
    height: auto;
}

</style>
